$triangle-color1: #00ADEE;
$triangle-color2: #5F94B8;
$triangle-color3: #172952;

$transition: .2s linear;

$media_width_xl: 1200px;
$media_width_lg: 992px;
$media_width_md: 768px;
$media_width_sm: 576px;

.triangle-animations {
    // position: absolute;

    // @media (max-width: $media_width_md) {
    //     display: none;
    // }

    // &#svg1{
    //     right: 0;
    //     top: 0;
    //     @media (min-width: $media_width_lg) {
    //         top: 20px;
    //         width: 670px;
    //     }
    //     @media (min-width: $media_width_xl) {
    //         min-width: 730px;
    //         max-width: 1050px;
    //         width: 50%;
    //     }
    // }

    svg {
        .markers {
            g {
                transition: $transition;
                opacity: 0;
            }

            g.triangles-show {
                opacity: 1;
            }
        }


    }
}